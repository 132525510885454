import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import Layout from "../components/layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";
import PagesPlaceHolder from "../components/placeholders/PagesPlaceHolder";
import { useSelector } from "react-redux";
import { selectData } from "../store/reducers/settings";

const TermsConditions = () => {

  const data = useSelector(selectData);
  const [isloading, setLoading] = useState(true);
  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  },[])

  return (
    <Layout title="Privacy Policy">
      <Breadcrumbs title="Terms & Conditions" crumb="Terms & Conditions" />
      <Container>
        <div className="title-wrapper">
          <Typography variant="h4" component="h4" className="bold">
            Terms & <span className="highlight">Conditions</span>
          </Typography>
          <Typography weight="light">
            ullamcorper morbi tincidunt ornare massa eget egestas
          </Typography>
        </div>
        <div className="privacy-content">
          {isloading ? (
            <PagesPlaceHolder />
          ) : (
            <>
              {" "}
              <Typography
                variant="body1"
                component="h5"
                dangerouslySetInnerHTML={{
                  __html: data && data.terms_conditions
                }}
              />
            </>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default TermsConditions;
