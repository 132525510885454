import React, { useState, useEffect } from "react";
import { Grid, Typography, Container, Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from "react-router-dom";
import * as api from "../../utils/api";
import { selectData } from "../../store/reducers/settings";
import { useSelector } from "react-redux";

const Footer = () => {

  const data = useSelector(selectData);

  const [cities, setCities] = useState();

  const url = process.env.REACT_APP_BECOME_PARTNER_URL;

  const parterurl = url.replace(/"/g,"");

  useEffect(() => {
    api
      .get_cities(3)
      .then((response) => {
        if (!response.error) {
          setCities(response.data);
        }
      })
      .catch(() => {});
      // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/images/background.jpg"
        })`
      }}
      className="footer-content-wrapper"
    >
      <div className="side-img-wrapper">
        <img
          src={process.env.PUBLIC_URL + "/images/red-chilli-paste.png"}
          alt="chilli"
        />
      </div>
      <Container>
        <div className="footer-wrapper">
          <Grid container spacing={2} justify="center">
            <Grid item md={3}>
              <div className="footer-logo-wrapper">
                <div className="footer-logo">
                  <img
                    src={data && data.web_settings[0].logo}
                    alt={"logo"}
                  />
                </div>
                <div className="footer-desc-wrapper">
                  <Typography color="#fff">
                    {data && data.web_settings[0].app_short_description}
                  </Typography>
                </div>
                <div className="become-partner" style={{ marginTop: "20px" }}>
                  <a
                    href={parterurl + "partner/auth/sign_up"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="contained" color="error">
                    Devenir un partenaire
                    </Button>
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item md={3} sx={{ width: "100%" }}>
              <div className="footer-services-wrapper">
                <div className="footer-desc-title">
                  <Typography color="#fff" variant="h6" component="h6">
                  Apprendre<span className="highlight"> Plus</span>
                  </Typography>
                </div>
                <div className="footer-services">
                  <ul>
                    <li>
                      <Link to="/privacy">
                        <Typography variant="subtitle1" component="h6">
                        Confidentialité
                        </Typography>
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">
                        <Typography variant="subtitle1" component="h6">
                        termes et conditions
                        </Typography>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">
                        <Typography variant="subtitle1" component="h6">
                        Contactez-nous
                        </Typography>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="footer-product-wrapper">
                <div className="footer-desc-title">
                  <Typography color="#fff" variant="h6" component="h6">
                  Nous <span className="highlight">livrons</span> à
                  </Typography>
                </div>
                <div className="footer-services">
                  <ul>
                    {cities &&
                      cities.map((city, index) => {
                        return (
                          <li key={index}>
                            <Typography variant="subtitle1" component="h6">
                              {city.name}
                            </Typography>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item md={3}>
              <div className="contactUs-wrapper">
                <div className="footer-desc-title">
                  <Typography color="#fff" variant="h6" component="h6">
                    <span className="highlight"> Contactez-nous</span> 
                  </Typography>
                </div>
                <div className="footer-services">
                  <Typography
                    color="#fff"
                    dangerouslySetInnerHTML={{
                      __html: data && data.web_settings[0].address
                    }}
                  />
                  <Typography color="#fff">
                    {data && data.web_settings[0].support_email}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="footer-bottom">
          <Grid container spacing={2}>
            <Grid item md={6}>
              <div className="footer-bottom-Typography ">
                <Typography color="#fff" variant="subtitle1" component="h6">
                Copyright © {new Date().getFullYear()} Made By -
                  <span className="highlight">
                    <a
                      href="https://wrteam.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      WRTeam
                    </a>
                  </span>
                </Typography>
              </div>
            </Grid>
            <Grid item md={6} justify="end">
              <div className="social-icons">
                <a href={data.web_settings[0].facebook_link} target="_blank" rel="noreferrer">
                  <FacebookIcon />
                </a>
                <a href={data.web_settings[0].instagram_link} target="_blank" rel="noreferrer">
                  <InstagramIcon />
                </a>

                <a href={data.web_settings[0].youtube_link} target="_blank" rel="noreferrer">
                  <YouTubeIcon />
                </a>
                <a href={data.web_settings[0].twitter_link} target="_blank" rel="noreferrer">
                  <TwitterIcon />
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default Footer;
