let config = {

  // defualt country set
  DefaultCountrySelectedInMobile: "in",

  //google Places key
  YOUR_GOOGLE_MAPS_API_KEY: "Aasdasdaasdfsadfasfsaf6546546j1lE",

};

export default config;
